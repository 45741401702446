import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";

const LinkStyled = styled(Link)`
  background: ${props => props.white ? "var(--white)" : "var(--secondary)"};
  color:${props => props.white ? "var(--primary)" : "var(--white)"};
  cursor: pointer;
  text-decoration: none;
  box-sizing: border-box;
  padding: 8px;
  border: 1px solid ${props => props.white ? "var(--white)" : "var(--secondary)"};
  text-align: center;
  font-weight: 600;
  height: 3.125rem;
  border-radius: 5px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: .3s;
  
  &:hover,
  &:focus {
    border-color: var(--lightenButton);
    background: var(--lightenButton);
    color: var(--white);
    transition: .3s;
  }
`;

const ExternalLink = styled.a`
  background: ${props => props.white ? "var(--white)" : "var(--secondary)"};
  color:${props => props.white ? "var(--primary)" : "var(--white)"};
  cursor: pointer;
  text-decoration: none;
  box-sizing: border-box;
  padding: 15px 8px;
  border: 1px solid ${props => props.white ? "var(--white)" : "var(--secondary)"};
  text-align: center;
  font-weight: 600;
  height: 3.125rem;
  border-radius: 5px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: .3s;

  &:hover,
  &:focus {
    border-color: var(--lightenButton);
    background: var(--lightenButton);
    color: var(--white);
    transition: .3s;
  }
`;

const CTA = ({to, children, white}) => {
    let isLinkExternal;
    if (to && (to.startsWith("http") || to.startsWith("www"))){
        isLinkExternal = true;
    }

    return (
      <>
        {isLinkExternal ?
          <ExternalLink href={to} target="_blank" white={white}>{children}</ExternalLink>
            :
          <LinkStyled to={to} white={white}>{children}</LinkStyled>}
      </>
    );
};

export default CTA;