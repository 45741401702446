import React from "react";
import styled from "styled-components";
import Container from "./Core/Container";
import breakpoints from "../styles/breakpoints";
import Section from "./Core/Section";

const Background = styled.div`
  background: var(--primary);
`;

const Usps = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
`;

const Usp = styled.div`
  width: 100%;
  padding: 1.25rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  color: var(--white);
  
  @media only screen and (min-width: ${breakpoints.sm}) {
    width: calc(100% / 3);
    padding: 2rem 1.25rem;
  }

  @media only screen and (min-width: ${breakpoints.md}) {
    width: ${props => props.uspsCount && `calc(100%/${props.uspsCount})`};
    min-width: 20%;
    padding: 2.5rem 1.25rem 1.5rem;
  }
`;

const Icon = styled.img`
  height: 3.375rem;
  width: 3.375rem;
  margin: 0 auto;
`;

const Copy= styled.p`
  text-align: center;
  max-width: 15.625rem;
  margin: 0.875rem auto 0;
`;

const USP = (props) => {
    const {usps} = props;
    const uspsCount = usps.length;

    return (
      <Section>
        <Container>
          <Background>
            <Usps>
              {usps.map((usp, index) => {
                    return (
                      <Usp key={index} uspsCount={uspsCount}>
                        <Icon src={usp.icon.asset.url} alt={usp.copy} />
                        <Copy>{usp.copy}</Copy>
                      </Usp>
                    );
                })}
            </Usps>
          </Background>
        </Container>
      </Section>
    );
};

export default USP;