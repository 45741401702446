import React from "react";
import styled from "styled-components";
import Container from "./Container";
import Section from "./Section";
import ImageCopyLink from "./ImageCopyLink"

const LinkList = styled.div`

`;



const ImageCopyLinkList = (props) => {

    let linkBlock = props.imageCopyLinks;

    return (
      <Section id={props.id ? props.id : null}>
        <Container>
          { props.heading  && 
            <h2>{ props.heading }</h2>
          }
          <LinkList>
            {linkBlock.map((block, index) => {
              return (
                <ImageCopyLink key={index} {...block}/>
              );
            })}
          </LinkList>
        </Container>
      </Section>

    );
};

export default ImageCopyLinkList;