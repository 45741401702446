import React from "react";
import styled from "styled-components";
import Section from "./Core/Section";
import Container from "./Core/Container";
import breakpoints from "../styles/breakpoints";
import PartnerCard from "./PartnerCard";

const Cards = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  row-gap: 1.25rem;
  column-gap: 1.25rem;
  margin-top: 1.25rem;

  @media only screen and (min-width: ${breakpoints.md}) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media only screen and (min-width: ${breakpoints.lg}) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

const PartnersList = ({heading, cards, id}) => {

    return (
      <Section id={id ? id : null}>
        <Container>
          <h2>{heading}</h2>
          <Cards>
            {cards.map((card, i) => {
                        return (
                          <PartnerCard key={i} text={card.text} image={card.image} url={card.url} />
                        );
                    })}
          </Cards>
        </Container>
      </Section>
    );
};

export default PartnersList;