import React from "react";
import { Row, Col } from "styled-bootstrap-grid";
import BackgroundImage from "gatsby-background-image";
import styled from "styled-components";
import BlockContent from "@sanity/block-content-to-react";
import Section from "./Core/Section";
import Container from "./Core/Container";
import breakpoints from "../styles/breakpoints";

const Block = styled.div`
  position: relative;
`;

const Background = styled(BackgroundImage)`
  height: 100%;
  width: 100%;
  position: absolute !important;
  top: 0;
  left: 0;
`;

const Overlay = styled.div`
  background: var(--codGrey);
  opacity: 0.5;
  height: 100%;
  width: 100%;
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
`;

const Content = styled.div`
  color: var(--white);
  position: relative;
  z-index: 9;
  margin: 3.125rem auto;

  a {
    display: inline;
    color: var(--primary);
    text-decoration: none;
    
    &:hover {
      text-decoration: underline;
    }

  }

  @media only screen and (min-width: ${breakpoints.lg}) {
    margin: 4rem auto;
  }
`;

const CopyWithBackground = (props) => {

  const serializers = {
    marks: {
      link: ({mark, children}) => {
        const { href } = mark
        return <a href={href} target="_blank" rel="noopener">{children}</a>
      },
      id: ({mark, children}) => {
        const { id } = mark
        return <span id={id}>{children}</span>
      },
    }
  }
    return (
      <Section>
        <Block>
          <Background fluid={props.backgroundImage.image.asset.fluid} alt={props.backgroundImage.alt} />
          <Container>
            <Row>
              <Col md={10} mdOffset={1} lg={8} lgOffset={2}>
                <Content>
                  <BlockContent blocks={props.text._rawCopy} serializers={serializers}/>
                </Content>
              </Col>
            </Row>
          </Container>
          <Overlay />
        </Block>
      </Section>
    );
};

export default CopyWithBackground;