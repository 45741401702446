import React from "react";
import { Row, Col } from "styled-bootstrap-grid";
import styled from "styled-components";
import BlockContent from "@sanity/block-content-to-react";
import BackgroundImage from "gatsby-background-image";
import Container from "./Container";
import breakpoints from "../../styles/breakpoints";
import ListIcon from "../../assets/icons/list.svg";
import Section from "./Section";

const CopyBlock = styled.div`
  box-sizing: border-box;
  padding: 2rem 1.25rem;
  background: ${props => props.backgroundColor ? props.backgroundColor.hex : "transparent"};
  color: ${props => props.copyColor ? props.copyColor.hex : "#000"};
  height: 100%;

  p {
    margin-bottom: 1.25rem;
  }
  
  ol {
    list-style: none;
    position: relative;
    
    li {
      margin-bottom: 1.25rem;
    }
    
    li:before {
      content: "";
      background: url(${ListIcon});
      height: 1.25rem;
      width: 1.25rem;
      display: block;
      position: absolute;
      left: 0;
    }
  }

  a {
    display: inline;
    color: var(--primary);
    text-decoration: none;
    
    &:hover {
      text-decoration: underline;
    }

  }

  @media only screen and (min-width: ${breakpoints.sm}) {
    padding: 2rem 1.25rem;
  }

  @media only screen and (min-width: ${breakpoints.md}) {
    padding: ${props => props.imagePosition ? !props.backgroundColor ? "3.875rem 1.25rem 3.875rem 0" : "3.875rem 1.25rem" : "3.875rem 1.25rem"};
  }

  @media only screen and (min-width: ${breakpoints.desktop}) {
    padding: ${props => props.imagePosition ? !props.backgroundColor ? "3.875rem 3.75rem 3.875rem 0": "3.875rem 3.75rem" : "3.875rem 3.75rem"};

  }
`;

const Background = styled(BackgroundImage)`
  height: 100%;
  min-height: 400px;
`;

const CopyCol = styled(Col)`
  height: auto;
`;

const ImageCopy = ({imagePosition, copy, image, backgroundColor, copyColor}) => {

  const serializers = {
    marks: {
      link: ({mark, children}) => {
        const { href } = mark
        return <a href={href} target="_blank" rel="noopener">{children}</a>
      },
      id: ({mark, children}) => {
        const { id } = mark
        return <span id={id}>{children}</span>
      },
    }
  }

    return (
      <Section>
        <Container fullWidthMobile>
          <Row>
            <Col md={6} lg={7} mdOrder={imagePosition && 2}>
              <Background fluid={image.image.asset.fluid} />
            </Col>

            <CopyCol md={6} lg={5}>
              <CopyBlock backgroundColor={backgroundColor} copyColor={copyColor} imagePosition={imagePosition}>
                <BlockContent blocks={copy._rawCopy} serializers={serializers} />
              </CopyBlock>
            </CopyCol>
          </Row>
        </Container>
      </Section>
    );
};

export default ImageCopy;