import React from "react";
import { Link } from "gatsby";
import { Row, Col } from "styled-bootstrap-grid";
import styled from "styled-components";
import BlockContent from "@sanity/block-content-to-react";
import BackgroundImage from "gatsby-background-image";
import breakpoints from "../../styles/breakpoints";
import ListIcon from "../../assets/icons/list.svg";
import Chevron from "../../assets/icons/chevron.svg";


const CopyBlock = styled.div`
  box-sizing: border-box;
  padding: 2rem;
  background: ${props => props.backgroundColor ? props.backgroundColor.hex : "transparent"};
  color: ${props => props.copyColor ? props.copyColor.hex : "#000"};
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  a {
    display: inline;
    color: var(--primary);
    text-decoration: none;
    
    &:hover {
      text-decoration: underline;
    }

  }

  span {
    width: 100%;
    font-size: 1rem;
    font-weight: 700;
    color: var(--primary);
    word-wrap: break-word;

    a {
      color: var(--primary);
      text-decoration: none;

      &:hover {
        text-decoration: none;
      }
    }

    &:after {
      content:"";
      background: url(${Chevron});
      height: 1rem;
      width: 1rem;
      margin-left: .5rem;
      border-radius: 50%;
      position: absolute;
      background-repeat: no-repeat;
      background-color: var(--primary);
      background-position: 60% 53%;
    }
  }
  
  p {
    margin-bottom: 1.25rem;
  }
  
  ol {
    list-style: none;
    position: relative;
    
    li {
      margin-bottom: 1.25rem;
    }
    
    li:before {
      content: "";
      background: url(${ListIcon});
      height: 1.25rem;
      width: 1.25rem;
      display: block;
      position: absolute;
      left: 0;
    }
  }

  @media only screen and (min-width: ${breakpoints.sm}) {
    padding: 2rem 3.375rem;
  }

  @media only screen and (min-width: ${breakpoints.md}) {
    padding: 3.875rem 3.75rem;
  }
`;

const Background = styled(BackgroundImage)`
  height: 100%;
  min-height: 400px;
`;

const BlockRow = styled(Row)`
    border-radius: 4px;
    overflow: hidden;
    margin-bottom: 1.25rem;
    box-shadow: -1px 6px 1rem -4px rgb(0 0 0 / 30%);
`;

const Block = styled(Link)`
    text-decoration: none;
`;

const External = styled.a`
    text-decoration: none;
`;

const ImageCopy = ({imagePosition, copy, image, backgroundColor, copyColor, linkCopy, link}) => {

  const serializers = {
    marks: {
      link: ({mark, children}) => {
        const { href } = mark;
        return <a href={href} target="_blank" rel="noopener noreferrer">{children}</a>;
      }
    },
    id: ({mark, children}) => {
      const { id } = mark
      return <span id={id}>{children}</span>
    },
  };

    return (
      <>
        { link && link.current.startsWith("/") ? (
          <Block to={link.current}>
            <BlockRow>
              <Col md={6} lg={7} mdOrder={imagePosition && 2}>
                <Background fluid={image.image.asset.fluid} />
              </Col>

              <Col md={6} lg={5}>
                <CopyBlock backgroundColor={backgroundColor} copyColor={copyColor}>
                  <BlockContent blocks={copy._rawCopy} serializers={serializers} />
                  { linkCopy && link ? 
                    <span>
                      <Link to={link.current}>{linkCopy}</Link> 
                    </span>
                  : null }
                </CopyBlock>
              </Col>
            </BlockRow>
          </Block>
        ) 
          : (
            <BlockRow>
              <Col md={6} lg={7} mdOrder={imagePosition && 2}>
                <External href={ link ? link.current : null} target="_blank">
                  <Background fluid={image.image.asset.fluid} />
                </External>
              </Col>
              <Col md={6} lg={5}>
                <CopyBlock backgroundColor={backgroundColor} copyColor={copyColor}>
                  <BlockContent blocks={copy._rawCopy} serializers={serializers} />
                  { linkCopy && link ? 
                  <span>
                    <a href={link.current} target="_blank" rel="noreferrer">{linkCopy}</a>
                  </span>
                  : null }
                </CopyBlock>
              </Col>
            </BlockRow>
        )}
      </>
    );
};

export default ImageCopy;