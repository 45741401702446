import React from "react";
import styled from "styled-components";
import Img from "gatsby-image";
import BlockContent from "@sanity/block-content-to-react";
import Section from "./Core/Section";
import Container from "./Core/Container";
import breakpoints from "../styles/breakpoints";

const Row = styled.div`
  display: grid;
  column-gap: 1.25rem;
  row-gap: 1.25rem;

  @media only screen and (min-width: ${breakpoints.sm}) {
    grid-template-columns: repeat(2, 1fr);
  }
  
  @media only screen and (min-width: ${breakpoints.md}) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

const Card = styled.div`
  box-sizing: border-box;
  width: 100%;
`;

const Number = styled.span`
  font-size: 1.875rem;
  line-height: 2.313rem;
  margin-bottom: 1.25rem;
  font-weight: bold;
  display: block;
`;

const Content = styled.div`
  padding: 1.438rem 1.25rem 2.5rem;

    a {
      color: ${props => props.linksColor ? props.linksColor : "var(--black)"};
    }
`;

const NumberedList = ({isOrderedList, cards, backgroundColor, textColor, linksColor, id}) => {
    const background = backgroundColor ? backgroundColor.hex : "#fff";
    const colorText = textColor ? textColor.hex : "var(--black)";

    const serializers = {
      marks: {
        link: ({mark, children}) => {
          const { href } = mark
          return <a href={href} target="_blank" rel="noopener">{children}</a>
        },
        id: ({mark, children}) => {
          const { id } = mark
          return <span id={id}>{children}</span>
        },
      }
    }

    return (
      <Section id={id ? id : null}>
        <Container>
          <Row>
            {cards.map((card, index) => {
                  return (
                    <Card key={index} style={{ backgroundColor: background, color: colorText }}>
                      { card.image && <Img fluid={card.image.image.asset.fluid} alt={card.image.alt} />}
                      <Content linksColor={linksColor ? linksColor.hex : ""}>
                        { isOrderedList && <Number>{index+1}</Number>}
                        <h3>{card.heading}</h3>
                        <BlockContent blocks={card.copy._rawCopy} serializers={serializers}/>
                      </Content>
                    </Card>
                  );
              })}
          </Row>
        </Container>
      </Section>
    );
};

export default NumberedList;