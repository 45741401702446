import React from "react";
import styled from "styled-components";
import Container from "./Container";
import CTA from "./CTA";
import breakpoints from "../../styles/breakpoints";

const LinksSection = styled.div`
  margin: 3.125em auto;
  
  h2 {
    margin-bottom: 0;
  }
`;

const Links = styled.div`
  margin-top: 1.25rem;
  display: grid;
  row-gap: 0.625rem;
  column-gap: 0.625rem;

  @media only screen and (min-width: 375px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media only screen and (min-width: ${breakpoints.xs}) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media only screen and (min-width: ${breakpoints.md}) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media only screen and (min-width: ${breakpoints.lg}) {
    grid-template-columns: repeat(5, 1fr);
  }

  @media only screen and (min-width: ${breakpoints.xl}) {
    grid-template-columns: repeat(6, 1fr);
  }
`;

const LinkBlock = styled.div`
  width: 100%;
`;

const LinkList = (props) => {
    const {heading, subHeading, links} = props;

    return (
      <LinksSection id={props.id ? props.id : null}>
        <Container>
          {heading && <h2>{heading}</h2>}
          {subHeading && <p>{subHeading}</p>}
          <Links>
            {links.map((link, index) => {
                  return (
                    <LinkBlock key={index}>
                      <CTA to={`/${link.slug}`}>{link.label}</CTA>
                    </LinkBlock>
                  );
              })}
          </Links>
        </Container>
      </LinksSection>
    );
};

export default LinkList;