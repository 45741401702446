import React from "react";
import { Row, Col } from "styled-bootstrap-grid";
import styled from "styled-components";
import Container from "./Core/Container";
import CTA from "./Core/CTA";
import breakpoints from "../styles/breakpoints";

const Section = styled.div`
  margin-top: 3.125rem;
  background: var(--primary);
  padding: 1.563rem 0;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  a {
    width: 10.875rem;
    margin-top: 1.25rem;
  }
  
  h3 {
    margin: 0.688rem 0;
    color: var(--white);
    padding-right: 1.25rem;
  }

  @media only screen and (min-width: ${breakpoints.sm}){
    flex-direction: row;
    
    a {
      margin-top: 0;
    }
    
    h3 {
      margin: 0.688rem 0;
      color: var(--white);
      width: calc(100% - 11rem);
      padding-right: 1.25rem;
    }
  }`;

const Banner = (props) => {

    return (
      <Section id={props.id ? props.id : null}>
        <Container>
          <Row>
            <Col sm={10} smOffset={1}>
              <Content>
                <h3>{props.content}</h3>
                <CTA to={props.url} white={1}>{props.label}</CTA>
              </Content>
            </Col>
          </Row>
        </Container>
      </Section>
    );
};

export default Banner;