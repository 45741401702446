import React from "react";
import styled from "styled-components";
import BlockContent from "@sanity/block-content-to-react";
import Container from "./Container";
import breakpoints from "../../styles/breakpoints";
import CTA from "./CTA";
import Section from "./Section";

const Row = styled.div`
  display: grid;
  row-gap: 1rem;
  column-gap: 1rem;

  @media only screen and (min-width: ${breakpoints.sm}) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media only screen and (min-width: ${breakpoints.md}) {
    row-gap: 1.25rem;
    column-gap: 1.25rem;
  }
`;

const IconBlock = styled.div`
  background: var(--primary);
  color: var(--white);
  width: 100%;
  text-align: center;
  padding: 2rem 1.25rem;
  box-sizing: border-box;
  
  p {
    font-size: 0.875rem;
    max-width: 15.625rem;
    margin: 0 auto;
  }
  
  @media only screen and (min-width: ${breakpoints.lg}) {
    padding: 3rem;
  }
`;

const H3 = styled.h3`
  margin: 1rem auto 0.25rem;
  max-width: 15.625rem;
  
  @media only screen and (min-width: ${breakpoints.lg}) {
    margin: 1.625rem auto 0.25rem;
  }
`;

const ButtonBlock = styled.div`
  width: 100%;
  margin: 1rem auto 0;

  @media only screen and (min-width: ${breakpoints.sm}) {
    width: calc(33% - 10px);
    margin: 0.625rem auto 0;
  }
`;

const Icon = styled.img`
  height: 80px;
  
  @media only screen and (min-width: ${breakpoints.lg}) {
    height: 125px;
  }
`;

const IconCopy = ({heading, blocks, cta, id}) => {

  let label; 
        let slug;

  if (cta) {
    label = cta.label;
    slug = cta.slug;
  } else {

  }

  const serializers = {
    marks: {
      link: ({mark, children}) => {
        const { href } = mark;
        return <a href={href} target="_blank" rel="noopener noreferrer">{children}</a>;
      },
      id: ({mark, children}) => {
        const { id } = mark;
        return <span id={id}>{children}</span>;
      },
    }
  };
    
    return (
      <Section id={id || null}>
        <Container>
          <h2>{heading}</h2>
          <Row>
            {blocks.map((block, index) => {
                return (
                  <IconBlock key={index}>
                    <Icon src={block.image.asset.url} alt={block.title} />
                    {block.title && <H3>{block.title}</H3>}
                    {block.text && <BlockContent blocks={block.text._rawCopy} serializers={serializers} />}
                  </IconBlock>
                );
            })}
          </Row>
          {(label && slug) && (
          <ButtonBlock>
            <CTA to={slug}>{label}</CTA>
          </ButtonBlock>
          )}
        </Container>
      </Section>
    );
};

export default IconCopy;