import React, {useState} from "react";
import styled from "styled-components";
import {Link} from "gatsby";
import Img from "gatsby-image";
import breakpoints from "../styles/breakpoints";

const Grid = styled.div`
  position: relative;
  height: 18.875rem;
  box-shadow: 0 1px 2px 0 rgba(0,0,0,.24);
`;

const Overlay = styled.div`
  background: var(--codGrey);;
  opacity: 0.2;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 2;
  transition: .2s;

  @media only screen and (min-width: ${breakpoints.lg}) {
    opacity: ${props => props.isHovered ? 0.2 : 0};
  }
`;

const Content = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  box-sizing: border-box;
  width: 100%;
  z-index: 9;
  padding: 0 1.25rem 1.25rem 1.25rem;
  color: var(--white);
  
  p {
    margin-bottom: 0.25rem;
  }
  
  h3 {
    margin: 0;
  }
`;

const Image = styled(Img)`
  height: 100%;
`;

const ImageGrid = ({heading, location, slug, image, i}) => {
    const [isHovered, setHover] = useState(null);

    return (
      <Grid onMouseEnter={() => setHover(i)} onMouseLeave={() => setHover(null)}>
        <Link to={`${slug}`}>
          <Image fluid={image.image.asset.fluid} alt={image.alt} />
          <Content>
            <p>{heading}</p>
            <h3>{location}</h3>
          </Content>
          <Overlay isHovered={isHovered === i} />
        </Link>
      </Grid>
    );
};

export default ImageGrid;