import React from "react";
import styled from "styled-components";
import breakpoints from "../styles/breakpoints";
import Container from "./Core/Container";
import Section from "./Core/Section";
import ImageGrid from "./ImageGrid";

const H2 = styled.h2`
`;

const Subheading = styled.p`
  margin-bottom: 1.25rem;
`;

const Grids = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  row-gap: 1.25rem;

  @media only screen and (min-width: ${breakpoints.md}){
    grid-template-columns: repeat(2, 1fr);
    column-gap: 1.25rem;
}

  @media only screen and (min-width: ${breakpoints.xl}){
    grid-template-columns: repeat(3, 1fr);
  }
`;

const ImageGridList = ({heading, subheading, grids, id}) => {
    return (
      <Section id={id ? id : null}>
        <Container>
          <H2>{heading}</H2>
          <Subheading>{subheading}</Subheading>
          <Grids>
            {grids ? grids.map((grid, i) => {
                    return (
                      <ImageGrid heading={grid.heading} image={grid.image} location={grid.location} slug={grid.slug} key={i} i={i} />
                    );
                }): null}
          </Grids>
        </Container>
      </Section>
    );
};

export default ImageGridList;