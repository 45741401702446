import React from "react";
import styled from "styled-components";
import Img from "gatsby-image";
import breakpoints from "../styles/breakpoints";

const Card = styled.a`
  height: 100%;
  width: 100%;
  display: block;
  background: var(--white);
  box-shadow: 0 2px 4px 0 rgba(0,0,0,.24);
  text-decoration: none;
  color: var(--black);
  cursor: pointer;
`;

const Image = styled(Img)`
  height: 16rem;

  @media only screen and (min-width: ${breakpoints.lg}){
    height: 12.813rem;
  }
`;

const Content = styled.p`
  padding: 1.25rem;
`;

const PartnerCard = ({text, image, url}) => {

    return (
      <Card href={url} target="_blank" rel="noreferrer">
        <Image fluid={image.image.asset.fluid} alt={image.alt} />
        <Content>{text}</Content>
      </Card>
    );
};

export default PartnerCard;